import { Grid } from '@mui/material';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store';
import { FormState } from 'store/states/forms/types';
import { Historic } from 'store/states/historics/types';

import { verifyRules } from 'utils/fieldNotebookUtils/verifyRules';
import { HistoricInputSelector } from '../components/HistoricInputSelector/HistoricInputSelector';
import { Input } from '../components/Input/Input';
import { Label } from '../components/Label/Label';
import { ThumbnailFullImage } from '../components/ThumbnailFullImage/ThumbnailFullImage';
import { ShowFullImage } from './styles';

interface HistoricContentProps {
  historic: Historic;
}

export function HistoricContent({ historic }: HistoricContentProps) {
  const { selectedForm } = useSelector<ReduxState, FormState>(
    (state) => state.forms,
  );

  const imageModalRef = useRef<ImperativeModalHandler>(null);
  const thumbnailModalRef = useRef<ImperativeModalHandler>(null);
  const drawModalRef = useRef<ImperativeModalHandler>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [signatureUrl, setSignatureUrl] = useState('');
  const [drawUrl, setDrawUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalSubtitle, setModalSubtitle] = useState('');
  const { t } = useTranslation();
  const handleOpenImageModal = (
    imageurl: string,
    title: string,
    subtitle: string,
  ) => {
    setImageUrl(imageurl);
    setModalTitle(title);
    setModalSubtitle(subtitle);
    imageModalRef.current?.open();
  };

  const handleOpenThumbnailModal = (
    signature: string,
    title: string,
    subtitle: string,
  ) => {
    setSignatureUrl(signature);
    setModalTitle(title);
    setModalSubtitle(subtitle);
    thumbnailModalRef.current?.open();
  };

  const handleOpenDrawModal = (
    draw: string,
    title: string,
    subtitle: string,
  ) => {
    setDrawUrl(draw);
    setModalTitle(title);
    setModalSubtitle(subtitle);
    drawModalRef.current?.open();
  };

  return (
    <>
      <Grid container spacing={3} mb={1}>
        <Grid item xs={12} md={3} sm={4}>
          <Label>{t('register_by')}</Label>
          <Input>{historic.user_name}</Input>
        </Grid>
        {selectedForm.fields.map((field, index) => {
          const answer = historic.answers.filter(
            (a) => a.field_id === field.field_id,
          )[0];
          const rulesSatisfied = verifyRules(
            field,
            historic.answers.map((historyAnswer) => ({
              field_id: historyAnswer.field_id,
              value: historyAnswer.answer,
              name_file: 'historyAnswer.answer_name_file'
            })),
          );

          return (
            rulesSatisfied && (
              <Grid item xs={12} md={3} sm={4} key={index}>
                <Label
                  dangerouslySetInnerHTML={{ __html: field.description }}
                />
                <HistoricInputSelector
                  field={field}
                  answer={answer?.answer}
                  formDate={historic.form_date}
                  openImageModal={handleOpenImageModal}
                  openThumbnailModal={handleOpenThumbnailModal}
                  openDrawModal={handleOpenDrawModal}
                />
              </Grid>
            )
          );
        })}
      </Grid>

      <Modal
        ref={imageModalRef}
        title={modalTitle}
        subtitle={modalSubtitle}
        hasCloseIcon
      >
        <ThumbnailFullImage imageUrl={imageUrl} />
      </Modal>
      <Modal
        ref={thumbnailModalRef}
        title={modalTitle}
        subtitle={modalSubtitle}
        hasCloseIcon
      >
        <ShowFullImage src={signatureUrl} alt="Signature Full Image" />
      </Modal>

      <Modal
        ref={drawModalRef}
        title={modalTitle}
        subtitle={modalSubtitle}
        hasCloseIcon
      >
        <ShowFullImage src={drawUrl} alt="Draw Full Image" />
      </Modal>
    </>
  );
}
