import {
  Divider,
  styled,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  IconButton,
} from '@mui/material';
import { themeOptions } from 'assets/styles/theme';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { List, ListRowRenderer } from 'react-virtualized';
import { addCustomInitialItemsOptions } from 'store/states/forms/actions';
import { Field, Item } from 'store/states/forms/types';
import CancelIcon from '@mui/icons-material/Cancel';

interface RadioBoxProps {
  setValue: (value: string) => void;
  defaultValue: string;
  items: {
    value: string;
    label: string;
  }[];
  onCustomItemAdded: (items: Item) => void;
  onCustomItemRemoved: (items: Item) => void;
  field: Field;
}

const StyledFomControlLabel = styled(FormControlLabel)`
  color: ${themeOptions.palette?.text?.primary};
`;

export default function RadioBox({
  items,
  setValue,
  defaultValue,
  onCustomItemAdded,
  onCustomItemRemoved,
  field,
}: RadioBoxProps) {

  const dispatch = useDispatch();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    const value =
      typeof event === 'string'
        ? event
        : (event.target as HTMLInputElement).value;

    setValue(value);
  };

  const [newItemValue, setNewItemValue] = useState<string>('');

  const canMoreOptionBeAdded = useMemo(() => {
    const { allowIncludeMoreOptions, limitIncludeMoreOptions } =
      field.rules || {};
    if (!allowIncludeMoreOptions) return false;
    const customItems = field.items.filter((item) => item.isCustom);
    return (
      !limitIncludeMoreOptions || customItems.length < limitIncludeMoreOptions
    );
  }, [field.items, field.rules]);

  const radioItems = !canMoreOptionBeAdded
    ? items
    : [
        ...items,
        {
          value: newItemValue || 'other',
          label: (
            <TextField
              sx={{
                width: '172%',
              }}
              value={newItemValue}
              onChange={(event) => setNewItemValue(event.target.value)}
              disabled={false}
              label={t('others')}
              placeholder={t('digitHere')}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyUp={(event) => {
                const value = newItemValue?.trim();
                if (event.key === 'Enter' && value) {
                  onCustomItemAdded({
                    label: value,
                    value,
                    id: value,
                    isCustom: true,
                    name: value,
                  });
                  handleChange(value);
                  setNewItemValue('');
                }
              }}
            />
          ),
        },
      ];

  useEffect(() => {
    const defaultValueTrim = defaultValue.trim();

    if (
      defaultValueTrim &&
      !items.find((item) => item.value === defaultValueTrim)
    ) {
      dispatch(
        addCustomInitialItemsOptions(field.field_id, [
          {
            id: defaultValueTrim,
            name: defaultValueTrim,
            label: defaultValueTrim,
            value: defaultValueTrim,
            isCustom: true,
          },
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const arrayLastItem = radioItems && radioItems.length - 1;

  const getRowHeight = ({ index }: { index: number }) => {
    if (index === arrayLastItem) {
      return 80;
    }
    return 55;
  };

  const rowCustomAdded = (item: Item) => ({
    value: item.value,
    label: (
      <TextField
        sx={{
          input: {
            cursor: 'pointer',
          },
          width: '145%',
        }}
        value={item.value}
        onChange={(event) => setNewItemValue(event.target.value)}
        disabled={false}
        label={t('other')}
        onClick={() => handleChange(item.value)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton onClick={() => onCustomItemRemoved(item)}>
              <CancelIcon
                style={{
                  color: themeOptions.palette.grey[400],
                  cursor: 'pointer',
                }}
              />
            </IconButton>
          ),
        }}
      />
    ),
  });

  const rowRenderer: ListRowRenderer = ({ index, key, style }) => {
    const currentItem = field.items[index] || {}; 
    const customRow = currentItem.isCustom ? rowCustomAdded(currentItem) : null;
  
    return (
      <div key={key} style={style}>
        {currentItem.isCustom && customRow && (
          <StyledFomControlLabel
            value={customRow.value}
            control={<Radio />}
            label={customRow.label}
          />
        )}
        {radioItems[index] && !currentItem.isCustom && (
          <StyledFomControlLabel
            value={radioItems[index]?.value}
            control={<Radio disabled={React.isValidElement(radioItems[index]?.label)}/>}
            label={radioItems[index]?.label}
          />
        )}
        {index !== arrayLastItem && <Divider />}
      </div>
    );
  };
  

  return (
    <FormControl
      sx={{ width: 'inherit' }}
      style={{ height: '100%', overflowY: 'auto' }}
      component="fieldset"
    >
      <RadioGroup
        onChange={handleChange}
        value={defaultValue}
        name="radio-buttons-group"
      >
        <List
          width={400}
          height={190}
          overscanRowCount={3}
          rowCount={radioItems.length}
          rowHeight={getRowHeight}
          rowRenderer={rowRenderer}
        />
      </RadioGroup>
    </FormControl>
  );
}

/* {items && items.map((option, index) => (
  <div key={index} />
))} */
