/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import {
  Historic, HistoricsActionType, HistoricState,
} from './types';
import { Answer } from '../forms/types';

const INITIAL_STATE: HistoricState = {
  historics: [],
  selectedHistoric: {} as unknown as Historic,
  pageNumber: 1,
  hasNextPage: true,
  filterOptions: {
    initialDate: '',
    finalDate: '',
    search: '',
  },
  loading: false,
  success: false,
  error: false,
  updateLoading: false,
  updateSuccess: false,
  updateError: false,
};

const historics: Reducer<HistoricState> = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  switch (action.type) {
    case HistoricsActionType.GET_HISTORICS_REQUEST:
    case HistoricsActionType.GET_ALL_HISTORICS_REQUEST: {
      draft.loading = true;
      break;
    }
    case HistoricsActionType.GET_HISTORICS_SUCCESS: {
      const { newHistorics, hasNextPage } = action.payload;
      const historicsData = [...state.historics, ...newHistorics];
      draft.historics = historicsData;

      draft.hasNextPage = hasNextPage;
      draft.loading = false;
      draft.success = true;
      draft.error = false;
      draft.pageNumber = hasNextPage ? draft.pageNumber += 1 : draft.pageNumber;
      break;
    }
    case HistoricsActionType.GET_HISTORICS_FAILURE: {
      draft.success = false;
      draft.loading = false;
      draft.error = true;
      break;
    }
    case HistoricsActionType.SET_HISTORIC_SELECTED: {
      const { historic } = action.payload;
      draft.selectedHistoric = historic;
      draft.updateSuccess = false;
      draft.updateError = false;
      break;
    }
    case HistoricsActionType.CLEAR_HISTORIC: {
      draft.pageNumber = 1;
      draft.hasNextPage = true;
      draft.historics = [];
      break;
    }
    case HistoricsActionType.UPDATE_HISTORICS: {
      const { historicAnswers } = action.payload;
      const historicIndex = draft.historics.findIndex((historic) => historic.form_date === draft.selectedHistoric.form_date);

      const processAnswers = draft.selectedHistoric.answers.map((answer) => {
        const historicAnswer = historicAnswers.find((a: Answer) => a.field_id === answer.field_id);
        return {
          ...answer,
          answer: historicAnswer.value,
          answer_name_file: historicAnswer.name_file,
        };
      });

      draft.historics[historicIndex].answers = processAnswers;
      break;
    }
    case HistoricsActionType.SET_FILTER_OPTIONS: {
      const { filterOptions } = action.payload;
      draft.filterOptions = { ...filterOptions };
      break;
    }
    case HistoricsActionType.UPDATE_ANSWERS_REQUEST: {
      draft.updateLoading = true;
      draft.updateSuccess = false;
      draft.updateError = false;
      break;
    }
    case HistoricsActionType.UPDATE_ANSWERS_SUCCESS: {
      draft.updateLoading = false;
      draft.updateSuccess = true;
      break;
    }
    case HistoricsActionType.UPDATE_ANSWERS_ERROR: {
      draft.updateLoading = false;
      draft.updateError = true;
      break;
    }
    default: {
      return state;
    }
  }
});

export default historics;
