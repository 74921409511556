export enum HistoricsActionType {
  GET_HISTORICS_REQUEST = '@historics/GET_HISTORICS_REQUEST',
  GET_ALL_HISTORICS_REQUEST = '@historics/GET_ALL_HISTORICS_REQUEST',
  GET_HISTORICS_SUCCESS = '@historics/GET_HISTORICS_SUCCESS',
  GET_HISTORICS_FAILURE = '@historics/GET_HISTORICS_FAILURE',
  SET_HISTORIC_SELECTED = '@historics/SET_HISTORIC_SELECTED',
  CLEAR_HISTORIC = '@historics/CLEAR_HISTORIC',
  UPDATE_ANSWERS_REQUEST = '@historics/UPDATE_ANSWERS_REQUEST',
  UPDATE_ANSWERS_SUCCESS = '@historics/UPDATE_ANSWERS_SUCCESS',
  UPDATE_ANSWERS_ERROR = '@historics/UPDATE_ANSWERS_ERROR',
  UPDATE_HISTORICS = '@historics/UPDATE_HISTORICS',
  SET_FILTER_OPTIONS = '@historics/SET_FILTER_OPTIONS',
}

export interface HistoricState {
  historics: Historic[];
  selectedHistoric: Historic;
  pageNumber: number;
  hasNextPage: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  updateLoading: boolean;
  updateSuccess: boolean;
  updateError: boolean;
  filterOptions: FilterOptions;
}

export interface Historic {
  monthly_appointment: number;
  form_name: string;
  user_name: string;
  user_role: string;
  form_date: number;
  updated_at?: number;
  updated_by?: string;
  answers: HistoricAnswer[];
}

export interface HistoricAnswer {
  answer_id: number;
  field_id: number;
  component_type: string;
  question: string;
  answer: string | null;
  answer_name_file: string | null;
  updated: boolean;
  old_value: string;
  rules?: Rules;
  component_depends: any[];
}

export interface Rules {
  needContainsAll: NeedContainsAll[];
  needContainsSome: any[];
}

export interface NeedContainsAll {
  field_id: number;
  value: string;
}

export interface FilterOptions {
  initialDate?: string;
  finalDate?: string;
  search?: string;
}
