import { AxiosResponse } from 'axios';
import {
  takeEvery, put, fork, select,
} from 'redux-saga/effects';
import { postAnswerForm } from 'services/fieldNotebookService';
import { verifyRules } from 'utils/fieldNotebookUtils/verifyRules';
import { showSnackbar } from '../snackbar/actions';
import {
  clearAnswersWhenEditAConditionalAnswer,
  nextStepSuccess,
  sendFormError,
  sendFormSuccess,
  setEditParams,
} from './actions';
import { Answer, Field, FormsActionTypes } from './types';
import { clearAnswersToNull, verifyNeedsCleanConditionalAnswersWhenEditing } from './utils';

function* sendAnswers(action: any) {
  const { successMessage, errorMessage } = action.payload;
  try {
    const { jwt } = yield select((state) => state.user);
    const { answers } = yield select((state) => state.forms.fillData);
    const { externalId } = yield select((state) => state.organization);
    const data = {
      response: {
        answers,
      },
    };

    data.response.answers = data.response.answers.map((answer: Answer) => {
      if (answer.value?.includes('\t')) {
          return {
            ...answer,
            value: answer.value.split('\t'),
        }
      }
      return answer;
    });

    const response: AxiosResponse = yield postAnswerForm({
      jwt,
      data,
      externalId,
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(sendFormSuccess());
      yield put(
        showSnackbar({ message: successMessage, typeMessage: 'success' }),
      );
    } else {
      yield put(sendFormError());
      yield put(showSnackbar({ message: errorMessage, typeMessage: 'error' }));
    }
  } catch (error) {
    yield put(sendFormError());
    yield put(showSnackbar({ message: errorMessage, typeMessage: 'error' }));
  }
}

function* verifyNextStep(action: any) {
  try {
    const { answer } = action.payload;
    const { forms } = yield select((state) => state);
    const { step, totalSteps, answers } = forms.fillData;
    const { fields } = forms.selectedForm;
    let currentAnswers : Answer[] = [...answers];
    const answerIndex = currentAnswers.findIndex((ans: { field_id: any; }) => ans.field_id === answer.field_id);
    const isNeedClearAnswers = verifyNeedsCleanConditionalAnswersWhenEditing(answer, currentAnswers, fields);

    if (isNeedClearAnswers) {
      yield put(clearAnswersWhenEditAConditionalAnswer(answerIndex));
      currentAnswers = clearAnswersToNull(currentAnswers, answerIndex);
    }
    currentAnswers[answerIndex] = answer;

    const newAnswers = answer ? [...currentAnswers, answer] : currentAnswers;
    let nextFieldIndex = step + 1;

    while (nextFieldIndex < totalSteps) {
      const nextField = fields[nextFieldIndex];

      if (!verifyRules(nextField, newAnswers)) {
        nextFieldIndex += 1;
      } else {
        break;
      }
    }
    if (nextFieldIndex <= totalSteps) {
      yield put(nextStepSuccess(answer, nextFieldIndex, answerIndex, false));
    } else {
      nextFieldIndex = totalSteps - 1;
      yield put(nextStepSuccess(answer, nextFieldIndex, answerIndex, true));
    }
  } catch (err) {
    console.error(err);
  }
}

function* editAnswerSettings(action: any) {
  try {
    const { field } = action.payload;
    const { forms } = yield select((state) => state);
    const { answers } = forms.fillData;
    const { fields } = forms.selectedForm;
    const fieldStack: number[] = [];
    const editingFieldIndex = fields.findIndex((searchField: Field) => searchField.field_id === field.field_id);

    answers.forEach((answer: Answer) => {
      const fieldIndex = fields.findIndex((searchField: Field) => searchField.field_id === answer.field_id);
      if (editingFieldIndex >= fieldIndex) {
        fieldStack.push(fieldIndex);
      }
    });

    yield put(setEditParams(editingFieldIndex, fieldStack));
  } catch (err) {
    console.error(err);
  }
}
function* watchGetArchivesRequest() {
  yield takeEvery(FormsActionTypes.SEND_FORM_REQUEST, sendAnswers);
}

function* watchNextStep() {
  yield takeEvery(FormsActionTypes.NEXT_STEP, verifyNextStep);
}

function* watchEditAnswer() {
  yield takeEvery(FormsActionTypes.EDIT_ANSWER, editAnswerSettings);
}

export const formSagas = [fork(watchGetArchivesRequest), fork(watchNextStep), fork(watchEditAnswer)];
